import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ec6cc1a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "huawei-charge"
};
const _hoisted_2 = {
  class: "number"
};
const _hoisted_3 = {
  class: "contrast"
};
const _hoisted_4 = _withScopeId(() => _createElementVNode("div", {
  class: "circle"
}, null, -1));
const _hoisted_5 = {
  class: "bubbles"
};
const _hoisted_6 = {
  class: "charging"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.battery.level.toFixed(0)) + "%", 1), _createElementVNode("div", _hoisted_3, [_hoisted_4, _createElementVNode("ul", _hoisted_5, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(15, i => {
    return _createElementVNode("li", {
      key: i
    });
  }), 64))])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(_ctx.batteryStatus), 1), _withDirectives(_createElementVNode("div", null, " เวลาที่เหลืออยู่：" + _toDisplayString(_ctx.calcDischargingTime), 513), [[_vShow, Number.isFinite(_ctx.battery.dischargingTime) && _ctx.battery.dischargingTime != 0]]), _withDirectives(_createElementVNode("span", null, " ระยะทางที่ต้องการจากแบตเตอรี่ถึงเต็ม：" + _toDisplayString(_ctx.calcDischargingTime), 513), [[_vShow, Number.isFinite(_ctx.battery.chargingTime) && _ctx.battery.chargingTime != 0]])])]);
}