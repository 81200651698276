import RouterView from '@/layout/routerView/index.vue';
import { t } from '@/hooks/useI18n';
const moduleName = 'dashboard';
const routes = [{
  path: '/dashboard',
  name: moduleName,
  redirect: '/dashboard/welcome',
  component: RouterView,
  meta: {
    title: t('routes.dashboard.dashboard'),
    icon: 'icon-yibiaopan'
  },
  children: [{
    path: 'welcome',
    name: `${moduleName}-welcome`,
    meta: {
      title: t('routes.dashboard.workbench'),
      icon: 'icon-shouye'
    },
    component: () => import('@/views/dashboard/welcome/index.vue')
  }]
}];
export default routes;