import { renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["Button"], _mergeProps($setup.props, {
    danger: ['danger'].includes($props.type),
    type: $setup.buttonType,
    class: [`ant-btn-${$props.type}`]
  }), _createSlots({
    _: 2
  }, [_renderList(_ctx.$slots, (_, key) => {
    return {
      name: key,
      fn: _withCtx(() => [_renderSlot(_ctx.$slots, key, {}, undefined, true)])
    };
  })]), 1040, ["danger", "type", "class"]);
}