import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "slide-up"
  }, {
    default: _withCtx(() => [$setup.isLock && $setup.isMouted && _ctx.$route.name != $setup.LOGIN_NAME ? (_openBlock(), _createBlock($setup["LockScreen"], {
      key: 0
    })) : _createCommentVNode("v-if", true)]),
    _: 1
  });
}