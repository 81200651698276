export const constantRouterComponents = {};
const modulesFiles = require.context('.', true, /\.ts$/);
modulesFiles.keys().forEach(path => {
  if (path.startsWith('./index.')) return;
  const value = modulesFiles(path).default;
  Object.entries(value).forEach(_ref => {
    let [path, comp] = _ref;
    constantRouterComponents[path] = comp;
  });
});
console.log('constantRouterComponents', constantRouterComponents);