import { computed, onMounted, reactive } from 'vue';
export const useBattery = () => {
  const battery = reactive({
    charging: false,
    chargingTime: 0,
    dischargingTime: 0,
    level: 100
  });
  const isSupported = navigator && 'getBattery' in navigator;
  const updateBattery = target => {
    for (const key in battery) {
      battery[key] = target[key];
    }
    battery.level = battery.level * 100;
  };
  const calcDischargingTime = computed(() => {
    const hour = battery.dischargingTime / 3600;
    const minute = battery.dischargingTime / 60 % 60;
    return `${~~hour}hr${~~minute}min`;
  });
  const batteryStatus = computed(() => {
    if (battery.charging && battery.level >= 100) {
      return 'Fullly-Charged';
    } else if (battery.charging) {
      return 'Charging';
    } else {
      return 'Discharge';
    }
  });
  onMounted(async () => {
    var _window$navigator$get, _window$navigator;
    const BatteryManager = (await ((_window$navigator$get = (_window$navigator = window.navigator).getBattery) === null || _window$navigator$get === void 0 ? void 0 : _window$navigator$get.call(_window$navigator))) || {};
    updateBattery(BatteryManager);
    BatteryManager.onchargingchange = _ref => {
      let {
        target
      } = _ref;
      updateBattery(target);
    };
    BatteryManager.onchargingtimechange = _ref2 => {
      let {
        target
      } = _ref2;
      updateBattery(target);
    };
    BatteryManager.ondischargingtimechange = _ref3 => {
      let {
        target
      } = _ref3;
      updateBattery(target);
    };
    BatteryManager.onlevelchange = _ref4 => {
      let {
        target
      } = _ref4;
      updateBattery(target);
    };
  });
  return {
    battery,
    isSupported,
    batteryStatus,
    calcDischargingTime
  };
};