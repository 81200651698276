import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import "core-js/modules/es.error.cause.js";
import axios from 'axios';
import { uniqueSlash } from './urlUtils';
import { ACCESS_TOKEN_KEY } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
import { useUserStore } from '@/store/modules/user';
const UNKNOWN_ERROR = 'Unknown error';
const baseApiUrl = process.env.VUE_APP_BASE_API;
const baseMockUrl = process.env.VUE_APP_MOCK_API;
const service = axios.create({
  timeout: 6000
});
service.interceptors.request.use(config => {
  const token = Storage.get(ACCESS_TOKEN_KEY);
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  Promise.reject(error);
});
service.interceptors.response.use(response => {
  const res = response.data;
  if (res.code !== 200) {
    _message.error(res.message || UNKNOWN_ERROR);
    if (res.code === 11001 || res.code === 11002) {
      window.localStorage.clear();
      window.location.reload();
    }
    const error = new Error(res.message || UNKNOWN_ERROR);
    error.code = res.code;
    return Promise.reject(error);
  } else {
    return res;
  }
}, error => {
  var _error$response$data$, _error$response, _error$response$data;
  const errMsg = (_error$response$data$ = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.message) !== null && _error$response$data$ !== void 0 ? _error$response$data$ : UNKNOWN_ERROR;
  _message.error(errMsg);
  error.message = errMsg;
  return Promise.reject(error);
});
export const request = async function (config) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  try {
    const {
      successMsg,
      errorMsg,
      permCode,
      isMock,
      isGetDataDirectly = true
    } = options;
    console.log('OPTIONS', isMock, config.url, options);
    if (permCode && !useUserStore().perms.includes(permCode)) {
      return _message.error('คุณไม่ได้รับอนุญาตให้เข้าถึงอินเทอร์เฟซนี้ โปรดติดต่อผู้ดูแลระบบ！');
    }
    const fullUrl = `${(isMock ? baseMockUrl : baseApiUrl) + config.url}`;
    config.url = uniqueSlash(fullUrl);
    console.info('WTF fullUrl', baseMockUrl, baseApiUrl, `[${fullUrl}]`, config.url);
    console.info('WTF before WTF');
    const res = await service.request(config);
    console.info('RES', isGetDataDirectly, res);
    successMsg && _message.success(successMsg);
    errorMsg && _message.error(errorMsg);
    return isGetDataDirectly ? res.data : res;
  } catch (error) {
    console.error('ERR2', error);
    return Promise.reject(error);
  }
};