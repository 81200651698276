import "ant-design-vue/es/result/style";
import _Result from "ant-design-vue/es/result";
import { createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.at.js";
import "core-js/modules/es.string.at-alternative.js";
import { notFound, errorRoute } from './staticModules/error';
import { REDIRECT_ROUTE } from './staticModules/besidesLayout';
import outsideLayout from './outsideLayout';
import RouterView from '@/layout/routerView/index.vue';
import { isUrl } from '@/utils/is';
import { uniqueSlash } from '@/utils/urlUtils';
import { constantRouterComponents } from '@/router/asyncModules';
import common from '@/router/staticModules';
import router, { routes } from '@/router';
import NotFound from '@/views/error/404.vue';
const endRoutes = [REDIRECT_ROUTE, errorRoute, notFound];
export function filterAsyncRoute(routes) {
  let parentRoute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let lastNamePath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return routes.filter(item => item.type !== 2 && item.isShow && item.parentId == (parentRoute === null || parentRoute === void 0 ? void 0 : parentRoute.id)).map(item => {
    const {
      router,
      viewPath,
      name,
      icon,
      orderNum,
      keepalive
    } = item;
    let fullPath = '';
    const pathPrefix = lastNamePath.at(-1) || '';
    if (isUrl(router)) {
      fullPath = router;
    } else {
      fullPath = router.startsWith('/') ? router : `/${router}`;
      fullPath = router.startsWith(pathPrefix) ? fullPath : pathPrefix + fullPath;
      fullPath = [...new Set(uniqueSlash(fullPath).split('/'))].join('/');
    }
    let realRoutePath = router;
    if (parentRoute) {
      if (fullPath.startsWith(parentRoute === null || parentRoute === void 0 ? void 0 : parentRoute.router)) {
        realRoutePath = fullPath.split(parentRoute.router)[1];
      } else if (!isUrl(parentRoute.router) && !isUrl(router)) {
        realRoutePath = router;
      }
    }
    realRoutePath = realRoutePath.startsWith('/') ? realRoutePath.slice(1) : realRoutePath;
    const route = {
      path: realRoutePath,
      name: fullPath,
      meta: {
        orderNum,
        title: name,
        type: item.type,
        perms: [],
        icon,
        namePath: lastNamePath.concat(fullPath),
        keepAlive: keepalive
      }
    };
    if (item.type === 0) {
      const children = filterAsyncRoute(routes, item, lastNamePath.concat(fullPath));
      if (children !== null && children !== void 0 && children.length) {
        route.component = RouterView;
        route.children = children;
        route.redirect = {
          name: children[0].name
        };
      } else {
        route.component = _createVNode(_Result, {
          "status": "500",
          "title": name,
          "sub-title": "เมนูประเภทไดเรกทอรีไม่ใช่หน้าจริง โปรดเพิ่มเมนูย่อยระดับหน้าสำหรับไดเรกทอรีปัจจุบันหรือเปลี่ยนประเภทเมนูปัจจุบัน."
        }, null);
      }
      return route;
    } else if (item.type === 1) {
      const Component = constantRouterComponents[viewPath] || NotFound;
      route.component = Component;
      const perms = routes.filter(n => n.parentId === item.id).flatMap(n => {
        var _n$perms;
        return (_n$perms = n.perms) === null || _n$perms === void 0 ? void 0 : _n$perms.split(',');
      });
      if (route.meta && perms) {
        route.meta.perms = perms;
      }
      return route;
    }
    return undefined;
  }).filter(item => !!item);
}
export const generatorDynamicRouter = asyncMenus => {
  try {
    console.log('asyncMenus', asyncMenus);
    const routeList = filterAsyncRoute(asyncMenus);
    const layout = routes.find(item => item.name == 'Layout');
    console.log(routeList, 'สร้างขึ้นตามเส้นทางการอนุญาตที่ส่งคืนโดยแบ็กเอนด์');
    generatorNamePath(common);
    const menus = [...common, ...routeList, ...endRoutes];
    layout.children = menus;
    const removeRoute = router.addRoute(layout);
    const filterRoutes = router.getRoutes().filter(item => {
      var _item$meta;
      return (!item.children.length || Object.is((_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.hideChildrenInMenu, true)) && !outsideLayout.some(n => n.name === item.name);
    });
    removeRoute();
    layout.children = [...filterRoutes];
    router.addRoute(layout);
    console.log('All routes', router.getRoutes());
    return Promise.resolve({
      menus,
      routes: layout.children
    });
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการสร้างเส้นทาง', error);
    return Promise.reject(`เกิดข้อผิดพลาดในการสร้างเส้นทาง: ${error}`);
  }
};
export const generatorNamePath = (routes, namePath, parent) => {
  routes.forEach(item => {
    if (item.meta && typeof item.name === 'string') {
      var _parent$meta, _item$children;
      item.meta.namePath = Array.isArray(namePath) ? namePath.concat(item.name) : [item.name];
      item.meta.fullPath = parent !== null && parent !== void 0 && (_parent$meta = parent.meta) !== null && _parent$meta !== void 0 && _parent$meta.fullPath ? [parent.meta.fullPath, item.path].join('/') : item.path;
      item.meta.fullPath = uniqueSlash(item.meta.fullPath);
      if ((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
        generatorNamePath(item.children, item.meta.namePath, item);
      }
    }
  });
};