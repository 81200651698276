export function setObjToUrlParams(baseUrl, obj) {
  let parameters = '';
  let url = '';
  for (const key in obj) {
    parameters += `${key}=${encodeURIComponent(obj[key])}&`;
  }
  parameters = parameters.replace(/&$/, '');
  if (/\?$/.test(baseUrl)) {
    url = baseUrl + parameters;
  } else {
    url = baseUrl.replace(/\/?$/, '?') + parameters;
  }
  return url;
}
export const uniqueSlash = path => path.replace(/(https?:\/)|(\/)+/g, '$1$2');