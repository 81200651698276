import "ant-design-vue/es/modal/style";
import _Modal from "ant-design-vue/es/modal";
import { defineStore } from 'pinia';
import router from '@/router';
import { store } from '@/store';
import { EVENT_KICK, EVENT_UPDATE_MENU } from '@/core/socket/event-type';
import { SocketIOWrapper, SocketStatus } from '@/core/socket/socket-io';
import { useUserStore } from '@/store/modules/user';
import { useTabsViewStore } from '@/store/modules/tabsView';
export const useWsStore = defineStore({
  id: 'ws',
  state: () => ({
    client: null,
    status: SocketStatus.CLOSE
  }),
  actions: {
    setClient(client) {
      this.client = client;
    },
    setStatus(status) {
      if (this.status === status) {
        return;
      }
      this.status = status;
    },
    initSocket() {
      var _this$client, _this$client$isConnec;
      if ((_this$client = this.client) !== null && _this$client !== void 0 && (_this$client$isConnec = _this$client.isConnected) !== null && _this$client$isConnec !== void 0 && _this$client$isConnec.call(_this$client)) {
        return;
      }
      const ws = new SocketIOWrapper();
      ws.subscribe(EVENT_KICK, async data => {
        const userStore = useUserStore();
        userStore.resetToken();
        _Modal.warning({
          title: '警告',
          content: `您已被管理员${data.operater}踢下线！`,
          centered: true,
          okText: '重新登录',
          onOk() {
            window.location.reload();
          }
        });
      });
      ws.subscribe(EVENT_UPDATE_MENU, async () => {
        const userStore = useUserStore();
        const tabsViewStore = useTabsViewStore();
        console.log('EVENT_UPDATE_MENU', EVENT_UPDATE_MENU);
        await userStore.afterLogin();
        const currentRoute = router.currentRoute.value;
        const hasRoute = router.hasRoute(currentRoute.name);
        if (Object.is(hasRoute, false)) {
          _Modal.warning({
            title: '提示',
            content: `您的权限已被管理员修改，暂无当前页面预览权限！`,
            centered: true,
            okText: '关闭当前页',
            onOk() {
              tabsViewStore.closeCurrentTab(currentRoute);
            }
          });
        }
      });
      this.setClient(ws);
    },
    closeSocket() {
      var _this$client2, _this$client2$close;
      (_this$client2 = this.client) === null || _this$client2 === void 0 ? void 0 : (_this$client2$close = _this$client2.close) === null || _this$client2$close === void 0 ? void 0 : _this$client2$close.call(_this$client2);
      this.setClient(null);
    }
  }
});
export function useWsStoreWithOut() {
  return useWsStore(store);
}