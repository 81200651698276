import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/404.gif';
const _withScopeId = n => (_pushScopeId("data-v-09c68b87"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-container"
};
const _hoisted_2 = _withScopeId(() => _createElementVNode("h1", null, "404", -1));
const _hoisted_3 = _withScopeId(() => _createElementVNode("h1", null, "OOPS！你好像走丢了...", -1));
const _hoisted_4 = _createTextVNode("Back to Home");
const _hoisted_5 = _withScopeId(() => _createElementVNode("img", {
  src: _imports_0,
  alt: ""
}, null, -1));
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_hoisted_2, _hoisted_3, _createVNode(_component_RouterLink, {
    to: {
      path: '/',
      replace: true
    },
    class: "ant-btn ant-btn-primary"
  }, {
    default: _withCtx(() => [_hoisted_4]),
    _: 1
  })]), _hoisted_5]);
}