import { ref, onMounted, onUnmounted } from 'vue';
export function useTime() {
  let timer;
  const year = ref(0);
  const month = ref(0);
  const week = ref('');
  const day = ref(0);
  const hour = ref(0);
  const minute = ref(0);
  const second = ref(0);
  const updateTime = () => {
    var _ref, _ref2;
    const date = new Date();
    year.value = date.getFullYear();
    month.value = date.getMonth() + 1;
    week.value = '日一二三四五六'.charAt(date.getDay());
    day.value = date.getDate();
    hour.value = ((_ref = `${date.getHours()}`) === null || _ref === void 0 ? void 0 : _ref.padStart(2, '0')) || new Intl.NumberFormat(undefined, {
      minimumIntegerDigits: 2
    }).format(date.getHours());
    minute.value = ((_ref2 = `${date.getMinutes()}`) === null || _ref2 === void 0 ? void 0 : _ref2.padStart(2, '0')) || new Intl.NumberFormat(undefined, {
      minimumIntegerDigits: 2
    }).format(date.getMinutes());
    second.value = date.getSeconds();
  };
  updateTime();
  onMounted(() => {
    clearInterval(timer);
    timer = setInterval(() => updateTime(), 1000);
  });
  onUnmounted(() => {
    clearInterval(timer);
  });
  return {
    month,
    day,
    hour,
    minute,
    second,
    week
  };
}