import { isNavigationFailure } from 'vue-router';
import NProgress from 'nprogress';
import { LOGIN_NAME, REDIRECT_NAME } from './constant';
import { useUserStore } from '@/store/modules/user';
import { useKeepAliveStore } from '@/store/modules/keepAlive';
import { ACCESS_TOKEN_KEY } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
import { to as _to } from '@/utils/awaitTo';
NProgress.configure({
  showSpinner: false
});
const defaultRoutePath = '/dashboard/welcome';
export function createRouterGuards(router, whiteNameList) {
  router.beforeEach(async (to, _, next) => {
    NProgress.start();
    const userStore = useUserStore();
    const token = Storage.get(ACCESS_TOKEN_KEY, null);
    if (token) {
      if (to.name === LOGIN_NAME) {
        next({
          path: defaultRoutePath
        });
      } else {
        const hasRoute = router.hasRoute(to.name);
        if (userStore.menus.length === 0) {
          const [err] = await _to(userStore.afterLogin());
          if (err) {
            userStore.resetToken();
            return next({
              name: LOGIN_NAME
            });
          }
          if (!hasRoute) {
            next({
              ...to,
              replace: true
            });
          } else {
            next();
          }
        } else {
          next();
        }
      }
    } else {
      if (whiteNameList.some(n => n === to.name)) {
        next();
      } else {
        next({
          name: LOGIN_NAME,
          query: {
            redirect: to.fullPath
          },
          replace: true
        });
      }
    }
  });
  const getComponentName = route => {
    var _route$matched$find, _route$matched$find$c;
    return (_route$matched$find = route.matched.find(item => item.name === route.name)) === null || _route$matched$find === void 0 ? void 0 : (_route$matched$find$c = _route$matched$find.components) === null || _route$matched$find$c === void 0 ? void 0 : _route$matched$find$c.default.name;
  };
  router.afterEach((to, from, failure) => {
    var _to$meta;
    const keepAliveStore = useKeepAliveStore();
    const token = Storage.get(ACCESS_TOKEN_KEY, null);
    if (isNavigationFailure(failure)) {
      console.error('failed navigation', failure);
    }
    const toCompName = getComponentName(to);
    if ((_to$meta = to.meta) !== null && _to$meta !== void 0 && _to$meta.keepAlive) {
      if (toCompName) {
        keepAliveStore.add(toCompName);
      } else {
        console.warn(`${to.fullPath} หาก KeepAlive ขององค์ประกอบของเพจเป็นจริงแต่ไม่ได้ตั้งชื่อคอมโพเนนต์ แคชจะใช้งานไม่ได้ โปรดตรวจสอบ`);
      }
    } else {
      if (toCompName) {
        keepAliveStore.remove(toCompName);
      }
    }
    if (to.name === REDIRECT_NAME) {
      const fromCompName = getComponentName(from);
      fromCompName && keepAliveStore.remove(fromCompName);
    }
    if (!token) {
      keepAliveStore.clear();
    }
    NProgress.done();
  });
  router.onError(error => {
    console.log(error, 'ข้อผิดพลาดในการกำหนดเส้นทาง');
  });
}