import { defineComponent as _defineComponent } from 'vue';
import { computed } from 'vue';
import { defineComponent as DO_defineComponent } from 'vue';
const __default__ = DO_defineComponent({
  name: 'svg-icon'
});
export default _defineComponent({
  ...__default__,
  props: {
    prefix: {
      type: String,
      default: 'svg-icon'
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 16
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    expose();
    const props = __props;
    const importAll = requireContext => requireContext.keys().forEach(requireContext);
    try {
      importAll(require.context('@/assets/icons', true, /\.svg$/));
    } catch (error) {
      console.log(error);
    }
    ;
    const symbolId = computed(() => `#${props.prefix}-${props.name}`);
    const getStyle = computed(() => {
      const {
        size
      } = props;
      const s = `${size}`.replace('px', '').concat('px');
      return {
        width: s,
        height: s
      };
    });
    const __returned__ = {
      importAll,
      props,
      symbolId,
      getStyle
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});