import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { defineStore } from 'pinia';
import { useKeepAliveStore } from './keepAlive';
import { store } from '@/store';
import { TABS_ROUTES } from '@/enums/cacheEnum';
import router from '@/router';
import { LOGIN_NAME, REDIRECT_NAME, PAGE_NOT_FOUND_NAME } from '@/router/constant';
export const blackList = [REDIRECT_NAME, LOGIN_NAME, PAGE_NOT_FOUND_NAME];
export const useTabsViewStore = defineStore({
  id: 'tabs-view',
  state: () => ({
    tabsList: []
  }),
  getters: {
    getTabsList: state => {
      return state.tabsList.filter(item => {
        var _item$meta;
        return !((_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.hideInTabs) && router.hasRoute(item.name);
      });
    },
    getCurrentTab: state => {
      const currentRoute = router.currentRoute.value;
      return state.tabsList.find(item => {
        var _item$meta2;
        return !((_item$meta2 = item.meta) !== null && _item$meta2 !== void 0 && _item$meta2.hideInTabs) && item.fullPath === currentRoute.fullPath;
      });
    }
  },
  actions: {
    delCompFromClosedTabs(closedTabs) {
      const keepAliveStore = useKeepAliveStore();
      const routes = router.getRoutes();
      const compNames = closedTabs.reduce((prev, curr) => {
        if (curr.name && router.hasRoute(curr.name)) {
          var _routes$find, _routes$find$componen, _routes$find$componen2;
          const componentName = (_routes$find = routes.find(n => n.name === curr.name)) === null || _routes$find === void 0 ? void 0 : (_routes$find$componen = _routes$find.components) === null || _routes$find$componen === void 0 ? void 0 : (_routes$find$componen2 = _routes$find$componen.default) === null || _routes$find$componen2 === void 0 ? void 0 : _routes$find$componen2.name;
          componentName && prev.push(componentName);
        }
        return prev;
      }, []);
      keepAliveStore.remove(compNames);
    },
    initTabs(routes) {
      this.tabsList = routes;
    },
    addTabs(route) {
      if (blackList.includes(route.name)) return false;
      const isExists = this.tabsList.some(item => item.fullPath == route.fullPath);
      if (!isExists) {
        this.tabsList.push(route);
      }
      return true;
    },
    closeLeftTabs(route) {
      const index = this.tabsList.findIndex(item => item.fullPath == route.fullPath);
      this.delCompFromClosedTabs(this.tabsList.splice(0, index));
    },
    closeRightTabs(route) {
      const index = this.tabsList.findIndex(item => item.fullPath == route.fullPath);
      this.delCompFromClosedTabs(this.tabsList.splice(index + 1));
    },
    closeOtherTabs(route) {
      const targetIndex = this.tabsList.findIndex(item => item.fullPath === route.fullPath);
      if (targetIndex !== -1) {
        const current = this.tabsList.splice(targetIndex, 1);
        this.delCompFromClosedTabs(this.tabsList);
        this.tabsList = current;
      }
    },
    closeCurrentTab(route) {
      const index = this.tabsList.findIndex(item => item.fullPath == route.fullPath);
      const isDelCurrentTab = Object.is(this.getCurrentTab, this.tabsList[index]);
      this.delCompFromClosedTabs(this.tabsList.splice(index, 1));
      if (isDelCurrentTab) {
        const currentRoute = this.tabsList[Math.max(0, this.tabsList.length - 1)];
        router.push(currentRoute);
      }
    },
    closeAllTabs() {
      this.delCompFromClosedTabs(this.tabsList);
      this.tabsList = [];
      localStorage.removeItem(TABS_ROUTES);
    },
    updateTabTitle(title) {
      const currentRoute = router.currentRoute.value;
      const upTarget = this.tabsList.find(item => item.fullPath === currentRoute.fullPath);
      if (upTarget) {
        upTarget.meta.title = title;
      }
    }
  }
});
export function useTabsViewStoreWithOut() {
  return useTabsViewStore(store);
}