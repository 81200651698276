import "ant-design-vue/es/button/style";
import _Button from "ant-design-vue/es/button";
import { defineComponent as _defineComponent } from 'vue';
import { computed } from 'vue';
import { buttonProps } from './button';
export default _defineComponent({
  __name: 'button',
  props: {
    ...buttonProps(),
    type: {
      type: String,
      default: 'default'
    }
  },
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    expose();
    const props = __props;
    const buttonTypes = ['default', 'primary', 'ghost', 'dashed', 'link', 'text'];
    const buttonType = computed(() => {
      const type = props.type;
      return buttonTypes.includes(type) ? type : ['danger'].includes(type) ? 'primary' : 'default';
    });
    const __returned__ = {
      props,
      buttonTypes,
      buttonType,
      Button: _Button
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});