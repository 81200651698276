import { request } from '@/utils/request';
export function updateAccountInfo(data) {
  return request({
    url: 'v1/account/info',
    method: 'post',
    data
  });
}
export function updatePassword(data) {
  return request({
    url: 'v1/account/password',
    method: 'post',
    data
  });
}
export function createHostname(data) {
  return request({
    url: 'v1/account/hostnames',
    method: 'post',
    data
  }, {
    isMock: false
  });
}
export function updateHostname(data) {
  return request({
    url: 'v1/account/hostnames',
    method: 'put',
    data
  }, {
    isMock: false
  });
}
export function deleteHostname(data) {
  return request({
    url: 'v1/account/hostnames',
    method: 'delete',
    data
  }, {
    isMock: false
  });
}
export function listHostname() {
  return request({
    url: 'v1/account/hostnames',
    method: 'get'
  }, {
    isMock: false
  });
}
export function getInfo() {
  return request({
    url: 'v1/account/info',
    method: 'get'
  }, {
    isMock: false
  });
}
export function permmenu() {
  return request({
    url: 'v1/account/permmenu',
    method: 'get'
  }, {
    isMock: false
  });
}
export function logout() {
  return request({
    url: 'v1/account/logout',
    method: 'post'
  });
}