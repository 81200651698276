import { request } from '@/utils/request';
export function login(data) {
  return request({
    url: 'v1/login',
    method: 'post',
    data
  }, {
    isGetDataDirectly: false,
    isMock: false
  });
}
export function getImageCaptcha(params) {
  return request({
    url: 'captcha/img',
    method: 'get',
    params
  }, {
    isMock: true
  });
}