import { i18n } from '@/locales';
function getKey(namespace, key) {
  if (!namespace) {
    return key;
  }
  if (key.startsWith(namespace)) {
    return key;
  }
  return `${namespace}.${key}`;
}
export function useI18n(namespace) {
  const normalFn = {
    t: key => {
      return getKey(namespace, key);
    }
  };
  if (!i18n) {
    return normalFn;
  }
  const {
    t
  } = i18n.global;
  const tFn = function (key) {
    if (!key) return '';
    if (!key.includes('.') && !namespace) return key;
    for (var _len = arguments.length, arg = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      arg[_key - 1] = arguments[_key];
    }
    return t(getKey(namespace, key), ...arg);
  };
  return Object.assign(i18n.global, {
    t: tFn
  });
}
export function transformI18n() {
  let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let isI18n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!message) {
    return '';
  }
  if (typeof message === 'object') {
    var _i18n$global;
    return message[(_i18n$global = i18n.global) === null || _i18n$global === void 0 ? void 0 : _i18n$global.locale];
  }
  if (isI18n && typeof message === 'string') {
    return i18n.global.t(message);
  }
  return message;
}
export const t = key => key;