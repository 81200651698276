import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.aggregate-error.js";
import "core-js/modules/es.aggregate-error.cause.js";
import "core-js/modules/es.array.at.js";
import "core-js/modules/es.array.find-last.js";
import "core-js/modules/es.array.find-last-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.reduce-right.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.object.has-own.js";
import "core-js/modules/es.promise.any.js";
import "core-js/modules/es.reflect.to-string-tag.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.string.at-alternative.js";
import "core-js/modules/es.string.match-all.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.immediate.js";
import "core-js/modules/web.structured-clone.js";
import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from './router';
import { setupStore } from '@/store';
import { setupI18n } from '@/locales';
import { setupAntd, setupAssets, setupGlobalMethods, setupCustomComponents } from '@/plugins';
const app = createApp(App);
function setupPlugins() {
  setupAntd(app);
  setupAssets();
  setupCustomComponents(app);
  setupGlobalMethods(app);
}
async function setupApp() {
  setupStore(app);
  await setupI18n(app);
  await setupRouter(app);
  app.mount('#app');
}
setupPlugins();
setupApp();