import "ant-design-vue/es/avatar/style";
import _Avatar from "ant-design-vue/es/avatar";
import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import { defineComponent as _defineComponent } from 'vue';
import { reactive } from 'vue';
import { LockOutlined, LoadingOutlined, UnlockOutlined, UserOutlined, ApiOutlined, ArrowRightOutlined, WifiOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import HuaweiCharge from './huawei-charge.vue';
import XiaomiCharge from './xiaomi-charge.vue';
import { useOnline } from '@/hooks/useOnline';
import { useTime } from '@/hooks/useTime';
import { useBattery } from '@/hooks/useBattery';
import { useLockscreenStore } from '@/store/modules/lockscreen';
import { useUserStore } from '@/store/modules/user';
import { LOGIN_NAME } from '@/router/constant';
export default _defineComponent({
  __name: 'lockscreen',
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    expose();
    const lockscreenStore = useLockscreenStore();
    const userStore = useUserStore();
    const {
      hour,
      minute
    } = useTime();
    const {
      online
    } = useOnline();
    const router = useRouter();
    const route = useRoute();
    const {
      battery,
      batteryStatus,
      calcDischargingTime
    } = useBattery();
    const randomCompName = Math.random() > 0.48 ? XiaomiCharge : HuaweiCharge;
    const state = reactive({
      isShowLogin: false,
      loginLoading: false,
      loginForm: {
        username: userStore.name,
        password: ''
      }
    });
    const unLockLogin = val => state.isShowLogin = val;
    const onLogin = async () => {
      if (state.loginForm.password.trim() == '') return _message.warn('Please enter password');
      state.loginLoading = true;
      state.loginLoading = false;
    };
    const nav2login = () => {
      unLockLogin(false);
      lockscreenStore.setLock(false);
      router.replace({
        name: LOGIN_NAME,
        query: {
          redirect: route.fullPath
        }
      });
    };
    const __returned__ = {
      lockscreenStore,
      userStore,
      hour,
      minute,
      online,
      router,
      route,
      battery,
      batteryStatus,
      calcDischargingTime,
      randomCompName,
      state,
      unLockLogin,
      onLogin,
      nav2login,
      LockOutlined,
      LoadingOutlined,
      UnlockOutlined,
      UserOutlined,
      ApiOutlined,
      ArrowRightOutlined,
      WifiOutlined,
      Avatar: _Avatar
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});