import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock($setup["ConfigProvider"], {
    locale: $setup.getAntdLocale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(_ref => {
        let {
          Component
        } = _ref;
        return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))];
      }),
      _: 1
    }), _createVNode($setup["LockScreen"])]),
    _: 1
  }, 8, ["locale"]);
}