import 'nprogress/css/nprogress.css';
import { createRouter, createWebHashHistory } from 'vue-router';
import { createRouterGuards } from './router-guards';
import outsideLayout from './outsideLayout';
import { whiteNameList } from './constant';
import { t } from '@/hooks/useI18n';
export const routes = [{
  path: '/',
  name: 'Layout',
  redirect: '/dashboard/welcome',
  component: () => import('@/layout/index.vue'),
  meta: {
    title: t('layout.header.home')
  },
  children: []
}, ...outsideLayout];
export const router = createRouter({
  history: createWebHashHistory(''),
  routes
});
export function resetRouter() {
  router.getRoutes().forEach(route => {
    const {
      name
    } = route;
    if (name && !whiteNameList.some(n => n === name)) {
      router.hasRoute(name) && router.removeRoute(name);
    }
  });
}
export async function setupRouter(app) {
  createRouterGuards(router, whiteNameList);
  app.use(router);
  await router.isReady();
}
export default router;