import { defineStore } from 'pinia';
import { store } from '@/store';
import { LOCALE_KEY } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
export const useLocaleStore = defineStore({
  id: 'locale',
  state: () => ({
    locale: Storage.get(LOCALE_KEY, 'th_TH')
  }),
  getters: {
    getLocale() {
      var _this$locale;
      return (_this$locale = this.locale) !== null && _this$locale !== void 0 ? _this$locale : 'th_TH';
    }
  },
  actions: {
    setLocale(locale) {
      this.locale = locale;
      Storage.set(LOCALE_KEY, locale);
    }
  }
});
export function useLocaleStoreWithOut() {
  return useLocaleStore(store);
}