import { set } from 'lodash-es';
export const loadLocalePool = [];
export function setHtmlPageLang(locale) {
  var _document$querySelect;
  (_document$querySelect = document.querySelector('html')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.setAttribute('lang', locale);
}
export function setLoadLocalePool(cb) {
  cb(loadLocalePool);
}
export function genMessage(langs) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lang';
  const obj = {};
  langs.keys().forEach(key => {
    const langFileModule = langs(key).default;
    let fileName = key.replace(`./${prefix}/`, '').replace(/^\.\//, '');
    const lastIndex = fileName.lastIndexOf('.');
    fileName = fileName.substring(0, lastIndex);
    const keyList = fileName.split('/');
    const moduleName = keyList.shift();
    const objKey = keyList.join('.');
    if (moduleName) {
      if (objKey) {
        set(obj, moduleName, obj[moduleName] || {});
        set(obj[moduleName], objKey, langFileModule);
      } else {
        set(obj, moduleName, langFileModule || {});
      }
    }
  });
  return obj;
}