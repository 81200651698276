import { ref, onMounted, onUnmounted } from 'vue';
export function useOnline() {
  const online = ref(true);
  const showStatus = val => {
    online.value = typeof val == 'boolean' ? val : val.target.online;
  };
  navigator.onLine ? showStatus(true) : showStatus(false);
  onMounted(() => {
    window.addEventListener('online', showStatus);
    window.addEventListener('offline', showStatus);
  });
  onUnmounted(() => {
    window.removeEventListener('online', showStatus);
    window.removeEventListener('offline', showStatus);
  });
  return {
    online
  };
}