import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { isEmpty, isFunction } from 'lodash-es';
import IO from 'socket.io-client';
import { useUserStore } from '@/store/modules/user';
import { useWsStore } from '@/store/modules/ws';
export var SocketStatus;
(function (SocketStatus) {
  SocketStatus["CONNECTING"] = "CONNECTING";
  SocketStatus["CONNECTED"] = "CONNECTED";
  SocketStatus["CLOSE"] = "CLOSE";
})(SocketStatus || (SocketStatus = {}));
export class SocketIOWrapper {
  constructor() {
    _defineProperty(this, "socketInstance", void 0);
    _defineProperty(this, "emitQueue", void 0);
    _defineProperty(this, "closeByServer", void 0);
    _defineProperty(this, "waiting", void 0);
    _defineProperty(this, "flushing", void 0);
    _defineProperty(this, "handleIndex", void 0);
    _defineProperty(this, "runningQueue", void 0);
    _defineProperty(this, "wsStore", useWsStore());
    this.socketInstance = null;
    this.emitQueue = [];
    this.runningQueue = [];
    this.handleIndex = 0;
    this.flushing = false;
    this.waiting = false;
    this.closeByServer = false;
    this._init();
  }
  getClientId() {
    if (this.socketInstance) {
      return this.socketInstance.id;
    }
    return undefined;
  }
  isConnected() {
    var _this$socketInstance;
    return (_this$socketInstance = this.socketInstance) === null || _this$socketInstance === void 0 ? void 0 : _this$socketInstance.connected;
  }
  close() {
    if (this.socketInstance) {
      this.socketInstance.close();
    }
    this.socketInstance = null;
  }
  _init() {
    if (this.socketInstance && this.socketInstance.connected) {
      throw new Error('socket is connecting');
    }
    const token = useUserStore().token;
    console.log('token', token);
    if (isEmpty(token)) {
      this.close();
      return;
    }
    this.changeStatus(SocketStatus.CONNECTING);
    this.socketInstance = IO(process.env.VUE_APP_BASE_SOCKET_NSP, {
      path: process.env.VUE_APP_BASE_SOCKET_PATH,
      transports: ['websocket'],
      query: {
        token
      }
    });
    this.socketInstance.on(SocketIOWrapper.staticEvents[0], this.handleConnectEvent.bind(this));
    this.socketInstance.on(SocketIOWrapper.staticEvents[1], this.handleErrorEvent.bind(this));
    this.socketInstance.on(SocketIOWrapper.staticEvents[2], this.handleDisconnectEvent.bind(this));
    this.socketInstance.io.on(SocketIOWrapper.staticEvents[8], this.handleReconnectAttemptEvent.bind(this));
  }
  subscribe(eventName, fn) {
    var _this$socketInstance2;
    if (isEmpty(eventName) || !isFunction(fn)) {
      throw new TypeError('param must correct type');
    }
    (_this$socketInstance2 = this.socketInstance) === null || _this$socketInstance2 === void 0 ? void 0 : _this$socketInstance2.on(eventName, fn);
  }
  unsubscribe(eventName, fn) {
    var _this$socketInstance3;
    if (isEmpty(eventName)) {
      throw new TypeError('param must correct type');
    }
    if (SocketIOWrapper.staticEvents.includes(eventName) && !isFunction(fn)) {
      throw new Error('default event remove must has second param');
    }
    (_this$socketInstance3 = this.socketInstance) === null || _this$socketInstance3 === void 0 ? void 0 : _this$socketInstance3.off(eventName, fn);
  }
  changeStatus(status) {
    this.wsStore.setStatus(status);
  }
  handleConnectEvent() {
    this.changeStatus(SocketStatus.CONNECTED);
    if (this.emitQueue.length > 0) {
      const queue = this.emitQueue.slice();
      this.emitQueue = [];
      for (let i = 0; i < queue.length; i++) {
        this.queueEmit(queue[i]);
      }
    }
  }
  handleReconnectAttemptEvent() {
    this.changeStatus(SocketStatus.CONNECTING);
  }
  handleDisconnectEvent(reason) {
    if (reason === 'io server disconnect') {
      this.closeByServer = true;
      this.changeStatus(SocketStatus.CLOSE);
    }
  }
  handleErrorEvent() {
    if (this.closeByServer) {
      this.changeStatus(SocketStatus.CLOSE);
    }
  }
  emit(eventName, data) {
    if (isEmpty(eventName) || SocketIOWrapper.staticEvents.includes(eventName)) {
      throw new TypeError('event is not allow emit');
    }
    if (!this.isConnected()) {
      this.emitQueue.push({
        eventName,
        data
      });
    } else {
      var _this$socketInstance4;
      (_this$socketInstance4 = this.socketInstance) === null || _this$socketInstance4 === void 0 ? void 0 : _this$socketInstance4.emit(eventName, data);
    }
  }
  resetState() {
    this.handleIndex = 0;
    this.runningQueue = [];
    this.waiting = this.flushing = false;
  }
  queueEmit(item) {
    if (!this.flushing) {
      this.runningQueue.push(item);
    } else {
      let i = this.runningQueue.length - 1;
      while (i > this.handleIndex) {
        i--;
      }
      this.runningQueue.splice(i + 1, 0, item);
    }
    if (!this.waiting) {
      this.waiting = true;
      setTimeout(this.flushQueue.bind(this), 0);
    }
  }
  flushQueue() {
    this.flushing = true;
    let item;
    for (this.handleIndex = 0; this.handleIndex < this.runningQueue.length; this.handleIndex++) {
      item = this.runningQueue[this.handleIndex];
      this.emit(item.eventName, item.data);
    }
    this.resetState();
  }
}
_defineProperty(SocketIOWrapper, "staticEvents", ['connect', 'connect_error', 'disconnect', 'disconnecting', 'newListener', 'removeListener', 'error', 'reconnect', 'reconnect_attempt', 'reconnect_error', 'reconnect_failed', 'ping', 'pong']);