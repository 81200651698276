import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { permissions } from './modules/';
import { useUserStore } from '@/store/modules/user';
export const formarPermsToCascader = () => {
  return Object.keys(permissions).reduce((prev, moduleKey) => {
    const module = permissions[moduleKey];
    Object.keys(module).forEach(key => {
      module[key].split(':').reduce((p, k, currentIndex, arr) => {
        const value = arr.slice(0, currentIndex + 1).join(':');
        const index = p.findIndex(item => (item === null || item === void 0 ? void 0 : item.value) === value);
        if (Number.isInteger(index) && index !== -1) {
          return p[index].children;
        } else {
          const item = {
            title: k,
            label: k,
            value,
            children: []
          };
          p.push(item);
          return item.children;
        }
      }, prev);
    });
    return prev;
  }, []);
};
export const verifyAuth = perm => {
  const permCode = perm.split('.').join(':');
  const permissionList = useUserStore().perms;
  return permissionList.some(n => n === permCode);
};
export default {
  install(app) {
    app.config.globalProperties.$auth = verifyAuth;
  }
};