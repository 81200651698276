import "ant-design-vue/es/config-provider/style";
import _ConfigProvider from "ant-design-vue/es/config-provider";
import { defineComponent as _defineComponent } from 'vue';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { transformI18n } from './hooks/useI18n';
import { LockScreen } from '@/components/basic/lockscreen';
import { useLocale } from '@/locales/useLocale';
export default _defineComponent({
  __name: 'App',
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    expose();
    const route = useRoute();
    const {
      getAntdLocale
    } = useLocale();
    watchEffect(() => {
      var _route$meta;
      if ((_route$meta = route.meta) !== null && _route$meta !== void 0 && _route$meta.title) {
        document.title = transformI18n(route.meta.title);
      }
    });
    const __returned__ = {
      route,
      getAntdLocale,
      ConfigProvider: _ConfigProvider,
      LockScreen
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});