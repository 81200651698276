import { createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6becd50e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "lock-box"
};
const _hoisted_2 = {
  class: "lock"
};
const _hoisted_3 = _withScopeId(() => _createElementVNode("h6", {
  class: "tips"
}, "Click to unlock", -1));
const _hoisted_4 = {
  key: 1,
  class: "login-box"
};
const _hoisted_5 = {
  class: "username"
};
const _hoisted_6 = {
  class: "local-time"
};
const _hoisted_7 = {
  class: "time"
};
const _hoisted_8 = {
  class: "computer-status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_input_search = _resolveComponent("a-input-search");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      unLockLogin: $setup.state.isShowLogin
    }, "lockscreen"]),
    onClick: _cache[2] || (_cache[2] = $event => $setup.unLockLogin(true)),
    onKeyup: _cache[3] || (_cache[3] = $event => $setup.unLockLogin(true)),
    onMousedown: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    onContextmenu: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
  }, [!$setup.state.isShowLogin ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    class: "lock-icon",
    title: "Unlock",
    onClick: _cache[0] || (_cache[0] = $event => $setup.unLockLogin(true))
  }, [_createVNode($setup["LockOutlined"]), _createVNode($setup["UnlockOutlined"])])]), _hoisted_3]), _createCommentVNode(" 小米 / 华为 充电"), (_openBlock(), _createBlock(_resolveDynamicComponent($setup.randomCompName), {
    battery: $setup.battery,
    "battery-status": $setup.batteryStatus,
    "calc-discharging-time": $setup.calcDischargingTime
  }, null, 8, ["battery", "battery-status", "calc-discharging-time"]))], 64)) : _createCommentVNode("v-if", true), $setup.state.isShowLogin ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode($setup["Avatar"], {
    size: 128
  }, {
    icon: _withCtx(() => [_createVNode($setup["UserOutlined"])]),
    _: 1
  }), _createElementVNode("div", _hoisted_5, _toDisplayString($setup.state.loginForm.username), 1), _createVNode(_component_a_input_search, {
    value: $setup.state.loginForm.password,
    "onUpdate:value": _cache[1] || (_cache[1] = $event => $setup.state.loginForm.password = $event),
    type: "password",
    autofocus: "",
    placeholder: "Enter your password",
    size: "large",
    onSearch: $setup.onLogin
  }, {
    enterButton: _withCtx(() => [$setup.state.loginLoading ? (_openBlock(), _createBlock($setup["LoadingOutlined"], {
      key: 0
    })) : (_openBlock(), _createBlock($setup["ArrowRightOutlined"], {
      key: 1
    }))]),
    _: 1
  }, 8, ["value"]), _createElementVNode("a", {
    style: {
      "margin-top": "10px"
    },
    onClick: $setup.nav2login
  }, "Unlock")])) : _createCommentVNode("v-if", true), !$setup.state.isShowLogin ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($setup.hour) + ":" + _toDisplayString($setup.minute), 1), _createCommentVNode(" <div class=\"date\">{{ month }} {{ day }} </div> ")]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", {
    class: _normalizeClass([{
      offline: !$setup.online
    }, "network"])
  }, [_createVNode($setup["WifiOutlined"], {
    class: "network"
  })], 2), _createVNode($setup["ApiOutlined"])])], 64)) : _createCommentVNode("v-if", true)], 34);
}