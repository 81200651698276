import "core-js/modules/es.array.push.js";
import { createI18n } from 'vue-i18n';
import { localeMap } from './config';
import { setHtmlPageLang, setLoadLocalePool } from './helper';
import { useLocaleStoreWithOut } from '@/store/modules/locale';
async function createI18nOptions() {
  var _defaultLocal$default, _defaultLocal$default2;
  const localeStore = useLocaleStoreWithOut();
  const locale = localeStore.getLocale;
  const defaultLocal = await import(`./lang/${locale}.ts`);
  const message = (_defaultLocal$default = (_defaultLocal$default2 = defaultLocal.default) === null || _defaultLocal$default2 === void 0 ? void 0 : _defaultLocal$default2.message) !== null && _defaultLocal$default !== void 0 ? _defaultLocal$default : {};
  setHtmlPageLang(locale);
  setLoadLocalePool(loadLocalePool => {
    loadLocalePool.push(locale);
  });
  return {
    locale,
    fallbackLocale: localeMap.en_US,
    messages: {
      [locale]: message
    },
    globalInjection: true,
    silentTranslationWarn: true,
    missingWarn: false,
    silentFallbackWarn: true
  };
}
const options = await createI18nOptions();
export const i18n = createI18n(options);
export async function setupI18n(app) {
  app.use(i18n);
}