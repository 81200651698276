import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b4e40ac2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "xiaomi-charge"
};
const _hoisted_2 = _createStaticVNode("<div class=\"line-box\" data-v-b4e40ac2><div class=\"line-left\" data-v-b4e40ac2></div><div class=\"line-left line-right\" data-v-b4e40ac2></div><div class=\"line-center line-center-left-2\" data-v-b4e40ac2></div><div class=\"line-center line-center-left-1\" data-v-b4e40ac2></div><div class=\"line-center\" data-v-b4e40ac2></div><div class=\"line-center line-center-right-1\" data-v-b4e40ac2></div><div class=\"line-center line-center-right-2\" data-v-b4e40ac2></div></div><div class=\"outer\" style=\"transform:scale(0.68);\" data-v-b4e40ac2><div class=\"circle circle-blur\" style=\"padding:30px;\" data-v-b4e40ac2></div></div>", 2);
const _hoisted_4 = {
  class: "outer"
};
const _hoisted_5 = {
  class: "text"
};
const _hoisted_6 = _withScopeId(() => _createElementVNode("span", {
  class: "sub"
}, "%", -1));
const _hoisted_7 = _withScopeId(() => _createElementVNode("div", {
  class: "light"
}, null, -1));
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, i => {
    return _createElementVNode("div", {
      key: i,
      class: "outer"
    }, [_createElementVNode("div", {
      class: "circle",
      style: _normalizeStyle({
        transform: `scale(${1.01 - 0.04 * (i - 1)})`
      })
    }, null, 4)]);
  }), 64)), _hoisted_2, (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, i => {
    return _createElementVNode("div", {
      key: i,
      class: "outer"
    }, [_createElementVNode("div", {
      class: "circle-white",
      style: _normalizeStyle({
        transform: `scale(${1 - 0.02 * (i - 1)})`,
        animationDuration: `${500 - 20 * (i - 1)}ms`
      })
    }, null, 4)]);
  }), 64)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.battery.level.toFixed(0)), 1), _hoisted_6])]), _hoisted_7]);
}