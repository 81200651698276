import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _mergeProps(_ctx.$attrs, {
    class: "svg-icon",
    style: $setup.getStyle,
    "aria-hidden": "true"
  }), [_createElementVNode("use", {
    "xlink:href": $setup.symbolId
  }, null, 8, _hoisted_1)], 16);
}