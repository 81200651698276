import { defineStore } from 'pinia';
import { useWsStore } from './ws';
import { store } from '@/store';
import { login } from '@/api/login';
import { ACCESS_TOKEN_KEY, USER_KEY } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
import { logout, getInfo, permmenu } from '@/api/account';
import { generatorDynamicRouter } from '@/router/generator-router';
import { resetRouter } from '@/router';
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    token: Storage.get(ACCESS_TOKEN_KEY, null),
    name: 'amdin',
    avatar: '',
    perms: [],
    menus: [],
    userInfo: {},
    user: Storage.get(USER_KEY, null)
  }),
  getters: {
    getToken() {
      return this.token;
    },
    getAvatar() {
      return this.avatar;
    },
    getName() {
      return this.name;
    },
    getPerms() {
      return this.perms;
    },
    getUser() {
      return this.user;
    }
  },
  actions: {
    resetToken() {
      this.avatar = this.token = this.name = '';
      this.perms = [];
      this.menus = [];
      this.userInfo = {};
      this.user = {};
      Storage.clear();
    },
    loadUser() {
      return this.user;
    },
    setToken(token) {
      this.token = token !== null && token !== void 0 ? token : '';
      const ex = 7 * 24 * 60 * 60 * 1000;
      Storage.set(ACCESS_TOKEN_KEY, this.token, ex);
    },
    setUser(user) {
      this.user = user !== null && user !== void 0 ? user : {};
      const ex = 7 * 24 * 60 * 60 * 1000;
      Storage.set(USER_KEY, this.user, ex);
    },
    async login(params) {
      try {
        const {
          token,
          user
        } = await login(params);
        console.info('WTF login user', user);
        this.setToken(token);
        this.setUser(user);
        return this.afterLogin();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async afterLogin() {
      try {
        const wsStore = useWsStore();
        const [userInfo, {
          perms,
          menus
        }] = await Promise.all([getInfo(), permmenu()]);
        console.log('permmenu', perms, menus);
        this.perms = perms;
        this.name = userInfo.name;
        this.avatar = userInfo.headImg;
        this.userInfo = userInfo;
        const generatorResult = await generatorDynamicRouter(menus);
        this.menus = generatorResult.menus.filter(item => {
          var _item$meta;
          return !((_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.hideInMenu);
        });
        !wsStore.client && wsStore.initSocket();
        return {
          menus,
          perms,
          userInfo
        };
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async logout() {
      await logout();
      const wsStore = useWsStore();
      wsStore.closeSocket();
      this.resetToken();
      resetRouter();
    }
  }
});
export function useUserStoreWithOut() {
  return useUserStore(store);
}