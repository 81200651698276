import { defineStore } from 'pinia';
import { IS_LOCKSCREEN } from '@/enums/cacheEnum';
import { store } from '@/store';
import { Storage } from '@/utils/Storage';
const initTime = 60 * 60;
const isLock = Storage.get(IS_LOCKSCREEN, false);
export const useLockscreenStore = defineStore({
  id: 'lockscreen',
  state: () => ({
    isLock: isLock === true,
    lockTime: isLock == 'true' ? initTime : 0
  }),
  actions: {
    setLock(isLock) {
      this.isLock = isLock;
      Storage.set(IS_LOCKSCREEN, this.isLock);
    },
    setLockTime() {
      let lockTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initTime;
      this.lockTime = lockTime;
    }
  }
});
export function useLockscreenStoreWithOut() {
  return useLockscreenStore(store);
}