import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HuaweiCharge',
  props: {
    battery: {
      type: Object,
      default: () => ({})
    },
    calcDischargingTime: {
      type: String,
      default: ''
    },
    batteryStatus: {
      type: String,
      validator: val => ['充电中', '已充满', '已断开电源'].includes(val)
    }
  }
});