export const localeMap = {
  th_TH: 'th_TH',
  en_US: 'en_US'
};
export const localeList = [{
  lang: localeMap.en_US,
  label: 'English',
  icon: '🇺🇸',
  title: 'Language'
}, {
  lang: localeMap.th_TH,
  label: 'Thai',
  icon: '🇹🇭',
  title: 'Language'
}];