import RouterView from '@/layout/routerView/index.vue';
import { t } from '@/hooks/useI18n';
const moduleName = 'dyndns';
const routes = [{
  path: '/dyndns',
  name: moduleName,
  redirect: {
    name: `${moduleName}-free-hostname`
  },
  component: RouterView,
  meta: {
    title: t('routes.dyndns.dyndns'),
    icon: 'icon-zhuomian'
  },
  children: [{
    path: 'free-hostname',
    name: `${moduleName}-free-hostname`,
    meta: {
      title: t('routes.dyndns.freehostname'),
      icon: 'icon-zhuomian'
    },
    component: () => import('@/views/dyndns/free-hostname.vue')
  }]
}];
export default routes;