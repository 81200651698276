import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent, unref, computed } from 'vue';
import { createFromIconfontCN } from '@ant-design/icons-vue';
import { isString } from '@/utils/is';
let scriptUrls = [`${process.env.BASE_URL}iconfont.js`];
let MyIconFont = createFromIconfontCN({
  scriptUrl: scriptUrls
});
export default defineComponent({
  name: 'IconFont',
  props: {
    type: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: 'icon-'
    },
    color: {
      type: String,
      default: 'unset'
    },
    size: {
      type: [Number, String],
      default: 14
    },
    scriptUrl: {
      type: String,
      default: ''
    }
  },
  setup(props, _ref) {
    let {
      attrs
    } = _ref;
    if (props.scriptUrl) {
      scriptUrls = [...new Set(scriptUrls.concat(props.scriptUrl))];
      MyIconFont = createFromIconfontCN({
        scriptUrl: scriptUrls
      });
    }
    const wrapStyleRef = computed(() => {
      const {
        color,
        size
      } = props;
      const fs = isString(size) ? parseFloat(size) : size;
      return {
        color,
        fontSize: `${fs}px`
      };
    });
    return () => {
      const {
        type,
        prefix
      } = props;
      return type ? _createVNode(MyIconFont, _mergeProps({
        "type": type.startsWith(prefix) ? type : `${prefix}${type}`
      }, attrs, {
        "style": unref(wrapStyleRef)
      }), null) : null;
    };
  }
});