import { defineComponent as _defineComponent } from 'vue';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import LockScreen from './lockscreen.vue';
import { useLockscreenStore } from '@/store/modules/lockscreen';
import { LOGIN_NAME } from '@/router/constant';
export default _defineComponent({
  __name: 'index',
  setup(__props, _ref) {
    let {
      expose
    } = _ref;
    expose();
    const lockscreenStore = useLockscreenStore();
    const route = useRoute();
    const isLock = computed(() => lockscreenStore.isLock);
    const lockTime = computed(() => lockscreenStore.lockTime);
    const isMouted = ref(false);
    let timer;
    const timekeeping = () => {
      clearInterval(timer);
      if (route.name === LOGIN_NAME || isLock.value) return;
      lockscreenStore.setLock(false);
      lockscreenStore.setLockTime();
      timer = setInterval(() => {
        lockscreenStore.setLockTime(lockTime.value - 1);
        if (lockTime.value <= 0) {
          lockscreenStore.setLock(true);
          return clearInterval(timer);
        }
      }, 1000);
    };
    onMounted(() => {
      document.addEventListener('mousedown', timekeeping);
      setTimeout(() => {
        isMouted.value = true;
      });
    });
    onUnmounted(() => document.removeEventListener('mousedown', timekeeping));
    const __returned__ = {
      lockscreenStore,
      route,
      isLock,
      lockTime,
      isMouted,
      timer,
      timekeeping,
      LockScreen,
      LOGIN_NAME
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});