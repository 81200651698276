import "ant-design-vue/es/form/style";
import _Form from "ant-design-vue/es/form";
import "ant-design-vue/es/input/style";
import _Input from "ant-design-vue/es/input";
import "ant-design-vue/es/modal/style";
import _Modal from "ant-design-vue/es/modal";
import "ant-design-vue/es/table/style";
import _Table from "ant-design-vue/es/table";
import "ant-design-vue/es/menu/style";
import _Menu from "ant-design-vue/es/menu";
import "ant-design-vue/es/card/style";
import _Card from "ant-design-vue/es/card";
import "ant-design-vue/es/checkbox/style";
import _Checkbox from "ant-design-vue/es/checkbox";
import "ant-design-vue/es/radio/style";
import _Radio from "ant-design-vue/es/radio";
import "ant-design-vue/es/col/style";
import _Col from "ant-design-vue/es/col";
import "ant-design-vue/es/row/style";
import _Row from "ant-design-vue/es/row";
import "ant-design-vue/es/select/style";
import _Select from "ant-design-vue/es/select";
import "ant-design-vue/es/tag/style";
import _Tag from "ant-design-vue/es/tag";
import "ant-design-vue/es/date-picker/style";
import _DatePicker from "ant-design-vue/es/date-picker";
import { AButton } from '@/components/basic/button/index';
import 'ant-design-vue/dist/antd.variable.min.css';
import 'dayjs/locale/zh-cn';
export function setupAntd(app) {
  app.component('AButton', AButton);
  app.use(_Form).use(_Input).use(_Modal).use(_Table).use(_Menu).use(_Card).use(_Checkbox).use(_Radio).use(_Col).use(_Row).use(_Select).use(_Tag).use(_DatePicker);
}