import "core-js/modules/es.array.reduce.js";
const modulesPermissionFiles = require.context('./', true, /\.ts$/);
export const generatePermCode = str => str.replace(/\//g, ':');
const filterDirs = ['/index.ts', './types.ts'];
export const permissions = modulesPermissionFiles.keys().reduce((modules, modulePath) => {
  if (filterDirs.some(n => modulePath.includes(n))) return modules;
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1').replace(/[-_/][a-z]/gi, s => s.substring(1).toUpperCase());
  const value = modulesPermissionFiles(modulePath).default;
  const permissionModule = Object.keys(value).reduce((obj, key) => {
    obj[key] = generatePermCode(value[key]);
    return obj;
  }, {});
  modules[moduleName] = permissionModule;
  return modules;
}, {});
console.log('permissions', permissions);